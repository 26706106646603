<template>

<el-card class="box-card">
  <div slot="header" class="clearfix">
    <!-- <span class="goback">返回</span> -->
    <el-button @click="$router.back();" type="text">返回</el-button>
    <el-button  @click="$router.push({path:'/user/userInfoEdit'});" style="float: right;margin-top:5px;" size="small" plain type="primary">修改</el-button>
  </div>
  <div  class="text">
    <el-form 
    ref="form" 
    :model="detailData"
    label-width="120px" 
    style="margin:10px;width:auto;" >
        <el-row :gutter="24">
            <el-col :span="10" style="line-height:36px;">
                <el-form 
                    label-width="80px"
                    style="margin:10px;width:auto;">
                    <el-form-item 
                        prop='order_sn'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            头像
                        </span>
                        <span>
                        	<img src="@/assets/images/avatar.png" style="width:50px;" alt="">
                        </span>
                    </el-form-item>

                    <el-form-item 
                        prop='order_sn'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            账号
                        </span>
                        <span>
                            xzh
                        </span>
                    </el-form-item>
                    <el-form-item  
                        prop='name'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            企业ID
                        </span>
                        <span>
                            3434987598
                        </span>
                    </el-form-item>
                    <el-form-item  
                        prop='date'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            电话号码
                        </span>
                        <span>
                            13543456543
                        </span>
                    </el-form-item>
                    <el-form-item 
                        label="" 
                        prop='order_count'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            邮箱
                        </span>
                        <span>
                           34543@qq.com
                        </span>
                    </el-form-item>
                  
                </el-form>
            </el-col>
            <el-col 
                :span="12" 
                style="line-height:36px;">
                <el-form 
                    label-width="80px"
                    style="margin:10px;width:auto;">
                    <el-form-item
                        prop='mat_name' 
                        style="textAlign:left;">
                        <span 
                            slot="label" 
                            class="comFontSize">
                            居住地址
                        </span>
                        <span>
                            广州市番禺区
                        </span>
                    </el-form-item>
                    <el-form-item 
                        prop='weight'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            公司
                        </span>
                        <span>
                           广州市XXX
                        </span>
                    </el-form-item>
                    <el-form-item 
                        prop='acre'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            职位
                        </span>
                        <span style="display:inline-block;vertical-align:super;">
                           嘻嘻
                        </span>
                    </el-form-item>
                    <el-form-item
                        prop='hand_at'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            部门
                        </span>
                        <span>
                            研发
                        </span>
                    </el-form-item>
                    <el-form-item
                        prop='count_price'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            注册时间
                        </span>
                        <span class="price">
                            2020-12
                        </span>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <br/>
    </el-form>

   </div>
</el-card>
</template>
<script>
    // import moment from "moment"
    export default {
        name:"orderDetails",
        data(){
            return {
                detailData:[],
                pdTableData:[],
                outTableData:[],
            }
        },
        // props:['orderNum'],
        created(){
            
        },
        mounted(){
           // console.log("123",this.orderNum);
           // this.getTableData();
        },
        methods: {

            getTableData(isFeater){
                this.isFetching=true;
                if(isFeater){
                    this.fetchOptions.page=1;
                }
                this.$store.dispatch("PMC_ORDER_DETAIL",{
                    order_sn:this.orderNum
                }).then((res)=>{
                    console.log("orderDetail",res)
                    this.detailData=res.list.det;
                    this.pdTableData=res.list.production;
                    this.outTableData=res.list.out;
                })
            },
        },
        watch: {
            orderNum(){
                this.getTableData();
            }
        },
    };
</script>

<style lang="less" scoped>
.box-card{
	margin:15px;

	/deep/.el-card__header{
	padding:10px 20px;
  }
}

.clearfix:before,.clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .goback{
  	font-size:16px;
  	margin-top:10px;
  	display: inline-block;
  	cursor:pointer;
  }
  

</style>
